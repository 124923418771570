<template>
  <CNavbar
    :sticky="true"
    expandable="md"
    class="p3 shadow nav-mobile"
    type="dark"
  >
    <CToggler in-navbar @click="hanedletoogleLeft" id="toogleButton">
      <font-awesome-icon icon="bars" size="lg"
    /></CToggler>
    <CNavbarBrand class="ml-auto mr-auto" href="#" @click="toOrderPage()">
      <img
        v-if="!loadingImage"
        style="height: 30px"
        :src="logo ? logo : require('@/assets/images/d-dot-logo.svg')"
        alt
      />
    </CNavbarBrand>
    <CToggler in-navbar @click="hanedletoogleRight" id="toogle-right">
      <b-avatar class="user-icon"></b-avatar>
    </CToggler>
    <div
      v-if="$store.state.isShowCloseButtonChatOrder"
      class="panel-close-profile-order pb-1 pt-2"
    >
      <span
        @click="$store.commit('UPDATE_SHOW_CLOSE_BUTTON_CHATORDER', false)"
        class="cursor-pointer my-auto"
      >
        <font-awesome-icon :icon="['fas', 'times']" class="" />
        ปิด
      </span>
    </div>
    <CCollapse :show="collapsedLeft" navbar>
      <CNavbarNav class="ml-auto mb-2 my-md-2 d-md-none">
        <div
          class="ml-md-2"
          v-if="
            $route.path !== '/login' && $route.path !== '/AccessDenied'
            //  && isShowHeaderTab
          "
        >
          <b-dropdown id="dropdown-chat-mode" variant="light" right>
            <template #button-content>
              <div
                class="d-flex justify-content-between align-items-center w-100"
              >
                <div>
                  {{ selectedChatModeName.name }}
                </div>
                <div>
                  {{ selectedChatModeName.totalCount }}
                </div>
              </div>
            </template>
            <b-dropdown-item
              v-for="(mode, bindex) in chatModeList"
              :key="bindex"
              @click="onChatModeChange(mode)"
              :class="{ active: `${chatModeId}` === `${mode.modeId}` }"
            >
              <div>
                {{ mode.name }}
              </div>
              <div class="total">
                {{ mode.totalCount }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          class="ml-md-2"
          v-if="
            $route.path !== '/login' && $route.path !== '/AccessDenied'
            //  && isShowHeaderTab
          "
        >
          <b-dropdown id="dropdown-channel-chat" variant="light" right>
            <template #button-content>
              <div class="d-flex  align-items-center w-100">
                <img
                  height="16"
                  :src="selectedChannelName.imageUrl"
                  :alt="selectedChannelName.name"
                  class="mr-2 rounded"
                  v-if="selectedChannelName.id != 99"
                />
                <div>
                  {{ selectedChannelName.name }}
                </div>
              </div>
            </template>
            <b-dropdown-item
              v-for="(channel, bindex) in socialChannelList"
              :key="bindex"
              @click="onChannelChange(channel)"
              :class="{ active: `${socialChannelId}` === `${channel.id}` }"
            >
              <img
                height="16"
                :src="channel.imageUrl"
                :alt="channel.name"
                class="mr-2 rounded"
                v-if="channel.id != 99"
              />
              <div>
                {{ channel.name }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </CNavbarNav>
    </CCollapse>
    <CCollapse :show="collapsedRight" navbar>
      <CNavbarNav class="ml-auto mb-2 my-md-2">
        <!-- <div
          class="ml-md-2"
          v-if="$route.path !== '/login' && $route.path !== '/AccessDenied'"
        > -->
        <!-- <b-button variant="light" @click="goToChat()">
          <font-awesome-icon class="mr-2 color-primary" icon="comments" />
          Chat
        </b-button>
        <b-button variant="light" @click="goToDOrder()" class="ml-2">
          <font-awesome-icon class="color-primary" icon="store" />
          D Order
        </b-button> -->
        <!-- </div> -->
        <div v-if="0">
          <div
            class="ml-md-2"
            v-if="
              $route.path !== '/login' && $route.path !== '/AccessDenied'
              //  && isStore == 1
              //  && isShowHeaderTab
            "
          >
            <b-button variant="light" v-on:click="setShowModalExport(true)">
              <font-awesome-icon
                class="mr-2 color-primary"
                icon="download"
              />Export Sheet
            </b-button>
          </div>
          <div
            class="ml-md-2"
            v-if="
              $route.path !== '/login' && $route.path !== '/AccessDenied'
              //  && isShowHeaderTab
            "
          >
            <b-button variant="light" v-on:click="setShowModalImport(true)">
              <font-awesome-icon
                class="mr-2 color-primary"
                icon="upload"
              />Import Sheet
            </b-button>
          </div>
        </div>

        <!-- <div
          class="ml-md-2"
          v-if="
            $route.path !== '/login' && $route.path !== '/AccessDenied'
            //  && isShowHeaderTab
          "
        >
          <b-dropdown
            id="dropdown-status"
            :text="selectedStatusName"
            variant="light"
            right
          >
            <template #button-content>
              <div
                :class="[
                  'label-status',
                  adminProfile.isAvailable
                    ? 'badge-online'
                    : !adminProfile.isAvailable
                    ? 'badge-offline'
                    : ''
                ]"
              ></div>
              <span> {{ selectedStatusName }} </span>
            </template>
            <b-dropdown-item
              v-for="(status, bindex) in statusList"
              :key="bindex"
              @click="onStatusChange(status)"
              :class="{ active: adminProfile.isAvailable === status.value }"
            >
              <div class="d-flex">
                <div
                  :class="[
                    'label-status',
                    status.value
                      ? 'badge-online'
                      : !status.value
                      ? 'badge-offline'
                      : ''
                  ]"
                ></div>
                {{ status.text }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div> -->
        <div
          class="ml-md-2"
          v-if="
            $route.path !== '/login' && $route.path !== '/AccessDenied'
            //  && isShowHeaderTab
          "
        >
          <b-dropdown
            id="dropdown-brand"
            :text="selectedBrandName"
            variant="light"
            right
          >
            <b-dropdown-item
              v-for="(brand, bindex) in brandLists"
              :key="bindex"
              @click="onBrandChange(brand)"
              :class="{ active: brandID === `${brand.id}` }"
            >
              <div>
                {{ brand.name }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div
          class="ml-md-2"
          v-if="$route.path !== '/login' && $route.path !== '/AccessDenied'"
        >
          <b-dropdown id="dropdown-logout" class="" variant="light" right>
            <template #button-content>
              <b-avatar class="user-icon px-2 py-1"></b-avatar>
              <span class="mx-2"> {{ username }} </span>
            </template>

            <b-dropdown-item @click="handleQrCode">
              <font-awesome-icon class="mr-2 color-primary" icon="qrcode" />QR
              Code
            </b-dropdown-item>
            <b-dropdown-item @click="handleLogout">
              <font-awesome-icon
                class="mr-2 color-primary"
                icon="sign-out-alt"
              />Log out
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </CNavbarNav>
    </CCollapse>
    <template v-if="forceRefresh">
      <div>
        <SelectBrand
          v-bind:isShow="isShowModalBrand"
          v-on:setShowModalBrand="setShowModalBrand"
          v-bind:onBrandChange="onBrandChange"
        />
        <ModalImport
          v-bind:isShow="isShowModalImport"
          v-bind:uploadTracking="uploadTracking"
          v-bind:shippingLists="shippingLists"
          v-on:setShowModalImport="setShowModalImport"
        />
        <ModalExportJobsheet
          v-bind:isShow="isShowModalExport"
          v-on:setShowModalExport="setShowModalExport"
        />
        <b-modal id="modal-1" v-model="result" hide-footer hide-header>
          <div class="m-3">
            <div class="mb-1">{{ resultMessage }}</div>
            <small
              class="text-danger"
              v-for="(item, index) in errorLists"
              :key="index + 'error'"
            >
              {{ item }}
            </small>
          </div>
        </b-modal>
        <ModalQRCode ref="modalQRCode" />
      </div>
    </template>
  </CNavbar>
</template>

<script>
import SelectBrand from "../Modals/ModalChangeBrand";
import ModalImport from "../Modals/ModalImportTracking";
import ModalExportJobsheet from "../Modals/ModalExportJobSheet";
import ModalQRCode from "@/components/modal/ModalQRCode.vue";
import { mapGetters } from "vuex";
import * as moment from "moment/moment";

export default {
  components: {
    SelectBrand,
    ModalImport,
    ModalExportJobsheet,
    ModalQRCode
  },
  data() {
    return {
      collapsedLeft: false,
      collapsedRight: false,
      isShowModalBrand: false,
      isShowModalExport: false,
      currentDate: "",
      currentTime: "",
      logo: null,
      result: false,
      resultMessage: "",
      errorLists: [],
      // brandLists: [],
      shippingLists: [],
      brandId: this.$route.query.brandID,
      textReg: /^\d+$/,
      config: {
        name: "BanilaCo"
      },
      forceRefresh: true,
      loadingImage: false,
      isShowModalImport: false,
      brandSelected: {
        id: 0,
        name: ""
      },
      isShowHeaderTab: true,
      isOrder: this.$route.meta.isOrder,
      chatModeSelected: {
        modeId: 0,
        name: "",
        totalCount: 0
      },
      channelSelected: {
        id: 99,
        name: "All Channel",
        imageUrl: ""
      },
      statusList: [
        { value: true, text: "Online" },
        { value: false, text: "Offline" }
      ]
    };
  },
  mounted() {
    this.interval = setInterval(this.time, 1000);
    this.$store.commit("setShowHeaderTab", false);
    this.isShowHeaderTab = this.$store.getters.getIsShowHeaderTab;
    this.changeFavicon(this.logo);
  },

  beforeDestroy() {
    clearInterval(this.interval);
  },
  computed: {
    username: {
      get: function() {
        return this.$store.getters.getUsername;
      },
      set: function() {}
    },
    selectedChatModeName() {
      let filtered = this.chatModeList.filter(
        ele => ele.modeId == this.chatModeId
      );
      if (filtered.length) {
        return filtered[0];
      } else {
        return {
          id: null,
          modeId: 0,
          name: "",
          sort: 0,
          totalCount: 0
        };
      }
    },
    selectedChannelName() {
      let filtered = this.socialChannelList.filter(
        ele => ele.id == this.socialChannelId
      );
      if (filtered.length) {
        return filtered[0];
      } else {
        return { id: 99, name: "All Channel", imageUrl: "" };
      }
    },
    selectedBrandName() {
      let filtered = this.brandLists.filter(ele => ele.id == this.brandID);
      if (filtered.length) {
        return filtered[0].name;
      } else {
        return "";
      }
    },
    selectedStatusName() {
      let filtered = this.statusList.filter(
        ele => ele.value == this.adminProfile.isAvailable
      );
      if (filtered.length) {
        return filtered[0].text;
      } else {
        return "";
      }
    },
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      brandID: "getBrand",
      // logo: "getBrandLogo",
      name: "getBrandName",
      mainColor: "getBrandColor",
      isStore: "getStoreFlag",
      brandLists: "getBrandLists",
      chatModeList: "getChatModeCount",
      chatModeId: "getChatMode",
      socialChannelId: "getChatSocialChannel",
      socialChannelList: "getChatSocialChannelList",
      adminProfile: "getChatAdminProfile"
    })
  },
  async created() {
    await this.getLogo();
  },
  methods: {
    moment: function() {
      return moment();
    },
    async getLogo() {
      this.loadingImage = true;
      this.$axios
        .get(this.$chatBaseUrl + `/Brand/BrandLogo/${this.brandId}`)
        .then(response => {
          this.loadingImage = false;
          if (response.data.result == 1) {
            this.logo = response.data.detail.value;
            this.$store.dispatch("setBrandLogo", response.data.detail.value);
            this.changeFavicon(this.logo);
          }
        });
    },
    goToDOrder() {
      var url = `/?brandID=${this.brandID || 1}`;
      this.$router.push(url);
    },
    goToChat() {
      // window.location.href = `/chat?brandID=${this.brandID}`;
      var url = `/chat?brandID=${this.brandID || 1}`;
      this.$router.push(url);
    },
    hanedletoogleLeft() {
      this.collapsedRight = false;
      this.collapsedLeft = !this.collapsedLeft;
    },
    hanedletoogleRight() {
      this.collapsedLeft = false;
      this.collapsedRight = !this.collapsedRight;
    },
    async uploadTracking(
      ShippingId,
      base64ExcelString,
      selectedUpdateCustomer
    ) {
      this.setShowModalImport(false);
      this.$store.dispatch("setIsLoading", true);
      this.$axios
        .put(this.$baseUrl + "/api/transaction/importTrackingNo", {
          ShippingId: ShippingId,
          base64ExcelString: base64ExcelString,
          sendMessage: selectedUpdateCustomer
        })
        .then(response => {
          if (response.data.result === 1) {
            this.setShowModalImport(false);
            this.result = true;
            this.resultMessage = "อัพโหลดไฟล์สำเร็จ";
            this.errorLists = [];
            this.$store.dispatch("setIsLoading", false);
          } else {
            this.result = true;
            this.resultMessage = response.data.message;
            this.errorLists = response.data.detail.error;
            this.$store.dispatch("setIsLoading", false);
          }
        });
    },
    time() {
      this.currentDate = this.moment().format(this.$formatDate);
      this.currentTime = this.moment().format("HH:mm:ss");
    },
    getShippingType() {
      this.$axios
        .get(this.$baseUrl + "/api/transaction/shippingList")
        .then(response => {
          if (response.data.result === 1) {
            this.shippingLists = response.data.detail;
          } else {
            alert(response.data.message);
          }
        });
    },
    setShowModalImport(value) {
      this.handleForcefresh();
      this.isShowModalImport = value;
    },
    setShowModalExport(value) {
      this.handleForcefresh();
      this.isShowModalExport = value;
    },
    setShowModalBrand(value, brandSelected) {
      this.handleForcefresh();
      this.isShowModalBrand = value;
      if (!this.isShowModalBrand) {
        if (brandSelected) {
          if (brandSelected !== this.brandID) {
            this.$store.dispatch("setBrand", brandSelected);
            this.brandId = brandSelected;
            window.location.href = `/?brandID=${brandSelected}`;
          }
        }
      }
    },
    backToHomePage() {
      // window.location.href = `${this.$backofficeUrl}?brandID=${this.brandID}`;
      window.location.href = `/chat?brandID=${this.brandID}`;
    },
    toOrderPage() {
      if (this.$cookies.isKey("d-order-session-token")) {
        window.location.href = `/?brandID=${this.brandID}`;
      } else {
        window.location.href = `/login?brandID=${this.brandID}`;
      }
    },
    backToBackofficeLoginPage() {
      window.location.href = `/login`;
      // window.location.href = `${this.$backofficeUrl}/login`;
    },
    onBrandChange(brandSelected) {
      if (brandSelected.id !== this.brandID) {
        this.$store.dispatch("setBrand", brandSelected);
        this.brandId = brandSelected.id;
        let path = this.$route.path;
        if (this.$route.name.toLowerCase() === "detail") path = "/";
        window.location.href = `${path}?brandID=${brandSelected.id}`;
      }
    },
    async onStatusChange(status) {
      await this.$store.dispatch("setIsLoading", true);
      await this.$store.dispatch(
        "updateChatAdminProfileIsAvailable",
        status.value
      );
      await this.$store.dispatch("setIsLoading", false);
    },
    onChatModeChange(chatModeSelected) {
      this.$store.dispatch("setActivePanel", 1);
      if (chatModeSelected.modeId !== this.chatModeId) {
        this.collapsedLeft = false;
        this.$store.dispatch("setChatMode", chatModeSelected.modeId);
      }
    },
    onChannelChange(channelSelected) {
      if (channelSelected.id !== this.socialChannelId) {
        this.collapsedLeft = false;
        this.$store.dispatch("setChatSocialChannel", channelSelected.id);
      }
    },
    handleForcefresh() {
      this.forceRefresh = false;
      this.$nextTick(() => {
        this.forceRefresh = true;
        this.$root.$emit("setConfig");
      });
    },
    changeFavicon(src) {
      var link =
        document.querySelector("link[rel*='icon']") ||
        document.createElement("link");
      link.type = "image/x-icon";
      link.rel = "shortcut icon";
      link.href = src;
      document.getElementsByTagName("head")[0].appendChild(link);
    },
    async handleLogout() {
      await this.$store.dispatch("updateChatAdminProfileIsAvailable", false);
      // var timestamp = new Date().getTime();

      // this.$cookies.set("master-chatagent-timestamp", timestamp);
      // this.$store.dispatch("setTimestamp", timestamp);

      await this.$cookies.remove("d-order-session-token");
      await this.$cookies.remove("d-order-username");

      // document.title = "Chat and Shop";
      document.title = "Chat";
      await this.changeFavicon("../../assets/images/d-dot-logo.svg");
      // location.href = "/login";
      this.backToBackofficeLoginPage();
    },
    handleQrCode() {
      this.$refs.modalQRCode.show();
    }
  }
};
</script>

<style>
.navbar {
  /* height: 55px; */
  background-color: white !important;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.dropdown-setting {
  background-color: #fca2ca !important;
  border-color: #fca2ca !important;
  color: white;
}
.text-username {
  color: black;
}

#toogleButton {
  color: black;
}
#toogle-right {
  padding: 0 !important;
  margin-top: 3px;
}
#toogle-right .user-icon {
  font-size: 20px;
  padding: 0px 5px;
}
#toogle-right .user-icon svg {
  margin-bottom: 5px;
}

#logout-nave {
  text-align: center;
}
#dropdown .btn {
  color: white;
}
.user-icon {
  background-color: rgba(113, 96, 196, 0.27);
  color: rgb(113, 96, 196);
}
.badge-primary {
  color: #fff;
  background-color: #832abf;
}
.header .dropdown-menu li.active > .dropdown-item {
  text-decoration: none;
  color: #455164;
  background-color: #e2dff3;
}
.header .dropdown-menu.dropdown-menu-right {
  padding: 0 !important;
}
#dropdown-chat-mode button,
#dropdown-channel-chat button {
  display: flex;
}
#dropdown-chat-mode .dropdown-toggle::after,
#dropdown-channel-chat .dropdown-toggle::after {
  margin: 8px 0px 8px 8px;
}
</style>
<style lang="scss" scoped>
.label-status {
  width: 20px;
  height: 20px;
  border-radius: 33px;
  margin-right: 5px;
}
::v-deep #dropdown-status {
  button {
    min-width: 100px;
    display: flex;
  }
  .dropdown-toggle::after {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
}
</style>
