<template>
  <div v-if="!isLoading">
    <b-modal
      id="modal-edit-name"
      title="Edit Display Name"
      body-class="p-0"
      centered
    >
      <div class="p-3">
        <InputText
          v-model="userChatInfo.linename"
          :textFloat="`${socialName} Name`"
          :placeholder="`${socialName} Name`"
          type="text"
          :isDisplay="true"
          isCopyClipboard
        />
        <InputText
          v-model="value"
          textFloat="Display Name"
          placeholder="Edit Display Name"
          type="text"
        />
      </div>
      <template #modal-footer>
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-button
            variant="outline-primary"
            class="mr-3"
            @click="$bvModal.hide('modal-edit-name')"
          >
            Cancel
          </b-button>

          <b-button variant="main-primary" @click="handleSubmit()" class="ml-3">
            Comfirm
          </b-button>
        </div>
      </template>
    </b-modal>
    <ModalLoading ref="modalLoading" />
  </div>
</template>

<script>
import ModalLoading from "../modal/ModalLoading.vue";
import InputText from "@/components/inputs/InputText";

export default {
  components: {
    ModalLoading,
    InputText
  },
  props: {
    userChatInfo: {
      type: [Array, Object],
      required: false
    },
    displayName: {
      type: String,
      required: false
    },
    socialName: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      data: [],
      value: "",
      isLoading: true
    };
  },
  mounted() {
    setTimeout(() => {
      this.value = this.displayName;
      this.isLoading = false;
    }, 100);
  },
  methods: {
    handleSubmit() {
      this.$emit("edit-name", this.value);
    },
    show() {
      this.$bvModal.show("modal-edit-name");
    },
    hide() {
      this.$bvModal.hide("modal-edit-name");
    }
  }
};
</script>

<style scoped>
::v-deep #tags {
  margin-left: 5px !important;
}

::v-deep .h-auto {
  height: 100px !important;
  overflow: auto;
}

::v-deep .text-truncate {
  margin-right: 5px;
  margin-left: 2px;
  font-size: 12px !important;
}

.btn-outline-primary {
  min-width: 150px;
}

::v-deep .b-form-tags-list .btn {
  color: #fff;
  background-color: #321fdb;
  border-color: #321fdb;
}

::v-deep .align-items-baseline {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin-right: 5px;
  margin-bottom: 5px;
  background-color: rgba(113, 96, 196, 0.27);
  border-radius: 2px;
  color: rgb(113, 96, 196);
  cursor: pointer;
}
</style>
