<template>
  <b-modal hide-footer hide-header v-model="showModal" size="md" centered>
    <div class="list-reply ">
      <img :src="path" class="img_upload-display" />
      <!-- <div
        class="img_upload-display"
        v-bind:style="{
          backgroundImage: 'url(' + path + ')'
        }"
      ></div> -->
      <div class="mt-3 d-flex w-100">
        <div class="mx-auto">
          <b-button @click="hide" variant="outline-primary" class="mr-1"
            >ปิด</b-button
          >
          <b-button
            @click="downloadImage"
            variant="main-primary"
            class="ml-1"
            :disabled="isLoading"
            >Download</b-button
          >
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  props: {
    path: {
      type: [String],
      required: false
    }
  },
  components: {},
  data() {
    return {
      showModal: false,
      isLoading: false
    };
  },
  methods: {
    show() {
      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    async downloadImage() {
      let fileName = this.path.split("/")[this.path.split("/").length - 1];
      let img = new Image();
      img.src = this.path;
      img.crossOrigin = "Anonymous";

      img.onload = () => {
        let canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        let ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        let dataURL = canvas.toDataURL();

        let fileLink = document.createElement("a");
        fileLink.href = dataURL;
        fileLink.setAttribute("download", `${fileName.split("/").pop()}`);
        document.body.appendChild(fileLink);
        fileLink.click();
      };
    }
  }
};
</script>

<style lang="scss" scoped>
.img_upload-display {
  width: 100%;
  aspect-ratio: 1;
  max-height: 500px;
  object-fit: scale-down;
  object-position: center center;
  // padding-bottom: 75%;
  // background-size: contain;
  // background-position: top center;
  // background-repeat: no-repeat;
  // background-color: inherit !important;
  cursor: pointer;
}
::v-deep .btn-main-primary,
::v-deep .btn-outline-primary {
  min-width: 150px !important;
}
</style>
