<template>
  <div>
    <div v-if="data.note" class="bg-note mx-3 mb-4">
      <b-row>
        <b-col>
          <div class="detail-note">
            <span>
              <font-awesome-icon icon="clock" class="icon-bd" />
              <span class="color-no-edit">
                <ChatDateTime :date="data.last_updated" />
              </span>
            </span>
          </div>
        </b-col>
      </b-row>
      <pre
        :class="[
          'px-3 mb-0 color-no-edit',
          { 'mission-description truncate--line-clamped': !isShowMore }
        ]"
        >{{ data.note }}</pre
      >
      <div class="text-center">
        <b-button
          v-if="!isShowMore"
          variant="show-more"
          class="border-0"
          @click="setShowMore"
        >
          Show More
          <font-awesome-icon icon="chevron-down" />
        </b-button>
        <b-button
          v-else
          variant="show-more"
          class="border-0"
          @click="setShowMore"
        >
          Show Less
          <font-awesome-icon icon="chevron-up" />
        </b-button>
      </div>
    </div>
    <div
      v-else
      class="d-flex justify-content-center align-items-center w-100 mb-4"
    >
      <span class="mt-1">No Data</span>
    </div>
  </div>
</template>

<script>
import ChatDateTime from "@/components/chat/ChatDateTime";
import { mapGetters } from "vuex";

export default {
  name: "NoteDetail",
  components: {
    ChatDateTime
  },
  props: {
    userId: {
      required: true,
      type: String
    },
    chatRoomId: {
      required: true,
      type: String
    },
    userDetail: {
      required: true,
      type: [Object]
    }
  },
  data() {
    return {
      data: {
        note: "",
        last_updated: "",
        sale_name: null,
        role_name: null
      },
      isShowMore: false
    };
  },
  // watch: {
  //   userId: {
  //     handler(newVal) {
  //       if (newVal) this.getList();
  //     },
  //     immediate: true
  //   }
  // },
  computed: {
    ...mapGetters({
      brandId: "getBrand"
    })
  },
  created() {
    if (!this.$store.state.isCheckChangeChat) {
      this.getList();
    }
  },
  methods: {
    setShowMore() {
      this.isShowMore = !this.isShowMore;
    },
    getList: async function() {
      this.isLoading = true;
      if (this.userDetail.user_guid) {
        await this.$axios
          .get(
            `${this.$chatBaseUrl}/crm/customer/note/${this.userId}/${this.userDetail.user_guid}/${this.chatRoomId}/${this.brandId}`
          )
          .then(response => response.data)
          .then(res => {
            if (res.result) {
              this.$store.commit("UPDATE_CHEACK_CHANGE_CHAT", true);
              this.data = res.detail;
            }
          })
          .catch(error => {
            this.isLoading = false;
            console.log("error", error);
          });
        this.isLoading = false;
      }
    },
    toUrlTicket(url) {
      window.open(`${url}`, "_blank").focus();
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-note {
  background-color: #e9e9e9;
  border-radius: 5px;
}
pre {
  font-family: kanit;
}
.edit-btn {
  text-decoration: underline;
}
.color-edit-btn {
  color: #0098f7;
  padding: 10px;
}
.color-no-edit {
  color: #000;
}
::v-deep .form-control:disabled,
.form-control[readonly] {
  background-color: #e9e9e9;
  opacity: 1;
  border: none;
  color: #000;
  overflow-y: hidden !important;
}
.icon-bd {
  color: #832abf;
}
.detail-note {
  padding: 10px;
}
.mission-description {
  white-space: break-spaces;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  max-height: 120px;
  overflow: hidden;
  transition: 0.3s;
  transition: 2s;
}
.truncate--line-clamped {
  -webkit-line-clamp: 5;
}
.btn-show-more {
  border: 1px solid #9450c2;
  color: #832abf;
}
.btn-show-more:active,
.btn-show-more:hover {
  border: 1px solid #9450c2;
  color: #832abf;
  text-decoration: underline;
}
</style>
