<template>
  <span>
    <!-- {{ date }} -->
    {{ getHumanDate() }}
  </span>
</template>

<script>
export default {
  name: "ChatDateTime",
  props: {
    date: {
      required: false,
      type: [String, Date]
    },
    isFullTime: {
      required: false,
      type: Boolean
    }
  },
  methods: {
    getHumanDate: function() {
      let result = "";
      if (this.isFullTime) {
        result = this.$moment(this.date).format("DD MMM YYYY HH:mm");
      } else {
        let duration = this.$moment.duration(
          this.$moment().diff(this.$moment(this.date))
        );
        let durationDays = duration.asDays();
        let isNotToday = this.$moment(this.date).isSame(
          this.$moment().subtract(1, "day"),
          "day"
        );
        let isNotCurrentYear = this.$moment(this.date).isSame(
          this.$moment().subtract(1, "year"),
          "year"
        );
        if (durationDays < 1 && !isNotToday) {
          result = this.$moment(this.date).format("HH:mm");
        } else if (durationDays < 2 && isNotToday) {
          result = "เมื่อวาน, " + this.$moment(this.date).format("HH:mm");
        } else if (!isNotCurrentYear) {
          result = this.$moment(this.date).format("DD MMM, HH:mm");
        } else {
          result = this.$moment(this.date).format("DD MMM YYYY");
        }
      }
      return result;
    }
  },
  data() {
    return {};
  },
  created: async function() {}
};
</script>

<style lang="scss" scoped>
@media (max-width: 767.98px) {
}
</style>
